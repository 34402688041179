import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import marked from "marked"

marked.setOptions({
  renderer: new marked.Renderer(),
  smartLists: true
})

const Text = ({
  text, // text content
  as = "div", // element type ex. "h1", "h2", "span" "p"...
  className,
  style
}) => {
  const viewerClasses = classNames("marked", className)

  // superscript symbols
  let convertedText = removeSymbols(text)

  // use marked to convert markdown if using default "div"
  if (as === "div") {
    convertedText = marked(convertedText)
  }

  return React.createElement(as, {
    style: style,
    className: viewerClasses,
    dangerouslySetInnerHTML: { __html: convertedText }
  })
}

export default Text

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  as: PropTypes.string,
  style: PropTypes.object
}

function removeSymbols(str) {
  str = str.replace(/[™®©]/g, "<sup>$&</sup>")
  let title = str.match(/title='(.*?)'/gi)
  if (title) {
    let newStr = str
    for (let i = 0; i < title.length; i++) {
      let newTitle = title[i].replace("<sup>", "").replace("</sup>", "")
      newStr = newStr.replace(title[i], newTitle)
    }
    return newStr
  } else {
    return str
  }
}
