import React from "react"
import classNames from "classnames"
import Seamless from "./SeamlessEmbed"
import "./ContactUs.scss"

const ContactUs = ({ colorBack }) => {
  const mainClasses = classNames("contact-form", {
    "color-back": colorBack
  })

  return (
    <div className={mainClasses}>
      <h3
        className="has-text-centered"
        style={{ margin: "0 0 30px 0", padding: 0 }}>
        Contact Us
      </h3>
      <Seamless src="https://secureform.seamlessdocs.com/f/a5e1994e78950168bad4c31d24e31539?embedded=true" />
    </div>
  )
}

export default ContactUs
