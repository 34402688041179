import React from "react"
import classNames from "classnames"

import TheaterVideo from "@components/Theater/TheaterVideo"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"

function ReferringProvider(props) {
  if (!props.data.hasThisSection) {
    return <div />
  }

  const mainEnglishClasses = classNames("continuum", "is-vcentered", {
    "color-back": props.colorBack
  })

  const post = props.data
  const language = "en"

  return (
    <>
      {language === "es" ? (
        <HorizontalTwoColumns
          className="continuum es-continuum"
          leftColWidth={3}
          rightColWidth={3}
          middleColWidth={2}
          leftColumn={
            <div
              className="column is-7"
              data-aos="fade-right"
              data-aos-duration="1200">
              <h2>Atención Continua de Nuestros Odontólogos Remitentes</h2>
              <p className="large">
                La conexión del consultorio con su red de remisiones es
                esencial. Nuestro equipo promueve una comunicación abierta entre
                nuestros consultorios remitentes de modo que los pacientes
                puedan disfrutar de una experiencia óptima, lo que en última
                instancia mejora la coordinación de su atención.
              </p>
            </div>
          }
          rightColumn={
            <div
              className="column"
              data-aos="fade-left"
              data-aos-duration="1200">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.imageId}
                responsive
              />
            </div>
          }
        />
      ) : (
        <section className={mainEnglishClasses}>
          <HorizontalTwoColumns
            leftColWidth={4}
            rightColWidth={4}
            middleColWidth={1}
            className="is-vcentered"
            leftColumn={
              <div
                className="column is-9"
                style={{ margin: "auto 0" }}
                data-aos="fade-right"
                data-aos-duration="1200">
                <h2 className="h3-style" style={{ marginTop: 0 }}>
                  {post.heading}
                </h2>
                <MarkdownViewer markdown={post.text} />
                <div className="referring-provider-theater-button-desktop">
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    playing
                    onlyButton
                    sizzleButton
                    buttonClass="sizzle-button-under contained"
                    compId="sizzleTheaterButton"
                    language={language}
                  />
                </div>
              </div>
            }
            rightColumn={
              <div
                className="column"
                data-aos="fade-left"
                data-aos-duration="1200">
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={post.imageId}
                  responsive
                />
                <p
                  style={{
                    color: "#6d6e6f",
                    fontSize: "14px",
                    lineHeight: "14px",
                    fontWeight: "bold",
                    marginTop: "19px",
                    marginBottom: "0"
                  }}>
                  {post.imageCaption}
                </p>
                <div className="referring-provider-theater-button-mobile">
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                    playing
                    onlyButton
                    sizzleButton
                    buttonClass="referring-provider-button contained"
                    compId="sizzleTheaterButton"
                    language={language}
                  />
                </div>
              </div>
            }
          />
        </section>
      )}
    </>
  )
}

export default ReferringProvider
